import { UserService } from './../../service/common/user.service';

import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChildren, ViewChild, Renderer } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormControlName } from '@angular/forms';
import { SharedService } from '../../service/common/shared.service';
import * as models from './../../models/models';
import { Router } from '@angular/router';
import { PersonalDataService } from './personal-data.service';
import { NavService, INavItem } from '../../service/common/nav.service';
import { Angular2Csv } from 'angular2-csv';

@Component({
  selector: 'personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})

export class PersonalDataComponent implements OnInit {
  public static HOME = '/projects';

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  @ViewChild('username', { static: false }) username: ElementRef;

  public gdprForm: FormGroup;
  public personalDetails: models.PersonalData[] = [];

  public userEmail: string;
  public showDeleteDialog: boolean;

  constructor(
    private sharedService: SharedService,
    private fb: FormBuilder,
    private router: Router,
    private user: UserService,
    private translate: TranslateService,
    private personalDataService: PersonalDataService,
    private navService: NavService) {
  }

  ngOnInit() {
    this.sharedService.changeBreadCrumb('gdpr');
    this.updateNav();
    this.initializeForm();
  }

  private updateNav() {
    try {
      this.navService.setNav([<INavItem>{
        routeName: 'Home',
        routeUrl: PersonalDataComponent.HOME
      }, <INavItem>{
        routeName: 'gdpr',
        routeUrl: undefined
      }]);
    } catch (err) {

    }
  }

  public isPersonalDataEmpty() {
    return this.personalDetails == null || this.personalDetails.length == 0;
  }

  public initializeForm() {
    this.gdprForm = this.fb.group({
      username: new FormControl('', Validators.required),
      tracking: new FormControl(false, Validators.required)
    });
  }

  public searchPersonalData() {
    const username = this.gdprForm.value.username;

    this.personalDetails = [];
    this.personalDataService.get(username).subscribe(result => {
      if (result == null) {
        this.userEmail = null;
        this.gdprForm.get('tracking').setValue(false);
      }
      else {
        this.userEmail = result.email;
        this.gdprForm.get('tracking').setValue(result.tracking);

        this.personalDetails = result.data;
      }
    });
  }
  public exportCSV() {

    const exportingData = this.personalDetails.map((value, index) => {
      return {
        [0]: index + 1,
        [1]: value.project,
        [2]: value.preparedBy,
        [3]: value.company,
        [4]: value.address,
        [5]: value.phoneNumber,
        [6]: value.email
      };
    });
    // tslint:disable-next-line
    new Angular2Csv(exportingData, 'gdpr', {
      showLabels: true,
      headers: [
        this.translate.instant('gdpr-header-ranking'),
        this.translate.instant('project-name'),
        this.translate.instant('prepared-by'),
        this.translate.instant('company'),
        this.translate.instant('address1'),
        this.translate.instant('phone'),
        this.translate.instant('email')]
    });
  }
  public deleteData() {
    this.showDeleteDialog = true;
  }
  public onDelete() {
    const username = this.gdprForm.value.username;
    this.personalDataService.remove(username).subscribe(result => {
      this.showDeleteDialog = false;
      this.searchPersonalData();
    });
  }
}
