import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '../../common/http-client';

import { environment } from 'environments/environment';
import { ConfigService } from '../config.service';

@Injectable()
export class CommonService {
  private _optionsUrl = '';

  constructor(private _http: HttpClient, private configService: ConfigService) {
    if (environment.localTesting) {
      this._optionsUrl = `${this.configService.config.serviceUrl}options/`;
    } else {
      this._optionsUrl = `${this.configService.config.serviceUrl}asws/profishvb/shear_connector_options/v1/`;
    }
  }

  getOptions(): Observable<any> {
    return this._http
      .get(`${this._optionsUrl}`);
  }
}
