import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subject, Observable ,  Subscription } from 'rxjs';
import { SharedService } from './../service/common/shared.service';
import * as models from './../models/models';
import { Component, OnInit } from '@angular/core';
import { EulaService } from './eula.service';
import { SettingsService } from '../settings/settings.service';
import { SessionStorageService } from '../service/util/session-storage.service';
import { AppConstants } from '../common/AppConstants';
import { Settings } from '../models/models';
import { CommonService } from '../service/common/common.service';
import { UserService } from 'app/service/common/user.service';
import { SettingsComponent } from '../settings/settings.component';
import * as _ from 'lodash';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EulaComponent implements OnInit {
  public eulaText: string;
  public selectedEulaLanguage: { culture, value, label };
  public selectedCountry: string;
  public countries: string[];
  public options: any;
  public eulaLanguages: Map<string, { culture, value, label, isoCode }[]>;
  public settings: Settings;
  public uiLanguages: models.Language[];
  public selectedUiLanguage: models.Language;
  public currentUiLanguage: models.Language;
  private subscription: Subscription;

  constructor(private sharedService: SharedService,
    private eulaService: EulaService,
    private settingsService: SettingsService,
    private commonService: CommonService,
    public sessionStorageService: SessionStorageService,
    private userService: UserService
    ) {
    this.eulaText = '';
  }

  ngOnInit() {
    this.eulaLanguages = this.sharedService.getSupportedLocales();

    this.countries = Array.from(this.eulaLanguages.keys());
    this.options = this.sessionStorageService.get(AppConstants.optionListStorageKey);

    if (this.options == null) {
      this.commonService.getOptions().subscribe(options => {
        this.options = options;
        this.initializeData();
      });
    } else {
      this.initializeData();
    }
  }

  initializeData() {
    const languagesOptions = this.options.languages;
    this.uiLanguages = _.sortBy(languagesOptions, [function (o) { return o.label; }]);

    this.settingsService.getSettings().subscribe(settings => {
      if (settings) {
        this.setDefualtCountry(settings);
        this.setDefaultUiLanguage(settings);
      } else {
        this.subscription = this.sharedService.$settings.subscribe(settings => {
          if (settings) {
            this.setDefualtCountry(settings);
            this.setDefaultUiLanguage(settings);
          }
        });
      }
    });
  }

  setDefualtCountry(settings) {
      const countryOfResidence = this.userService.getAuthentication().countryOfResidence;
      this.settings = settings;
      if (countryOfResidence) {
        let country = 'none';
         this.eulaLanguages.forEach((item, key) => {
          if (item[0].isoCode === countryOfResidence) {
             country = key;
          }
        });
        if (country) {
          this.selectedCountry = country;
          this.setEulaLanguage(this.eulaLanguages.get(country)[0]);
        } else {
          this.selectedCountry = 'none';
          this.setEulaLanguage(this.eulaLanguages.get('none')[0]);
        }
        return;
      }
      let currentCountry = this.countries[4];
      const countries: Array<any> = this.options['nationalAnnexes'];
      if (countries.length > 0) {
        const currentNationalAnnexes = countries.filter(c => c.id === settings.countryNationalAnnexes);
        if (currentNationalAnnexes.length > 0) {
          const nationalCountry = this.countries.find(c => c === currentNationalAnnexes[0].labelKey);
          currentCountry = nationalCountry ? nationalCountry : currentCountry;
        }
      }
      this.selectedCountry = currentCountry;
      const currentCountryEulaLanguages = this.eulaLanguages.get(currentCountry);
      const countryEulaLanguage = currentCountryEulaLanguages.find(l => l.value === this.settings.language);
      this.setEulaLanguage(countryEulaLanguage == null ? currentCountryEulaLanguages[0] : countryEulaLanguage);
  }

  setDefaultUiLanguage(settings) {
    this.selectedUiLanguage = this.uiLanguages.filter(item => (item.value === (settings && settings.language ? settings.language || 'en' : 'en')))[0];
    this.currentUiLanguage = this.selectedUiLanguage;
  }

  countryChange(event: string) {
    if (this.selectedCountry !== event) {
      this.selectedCountry = event;
      const currentCountryEulaLanguages = this.eulaLanguages.get(this.selectedCountry);
      const countryEulaLanguage = currentCountryEulaLanguages.find(l => l.value === this.settings.language);
      this.setEulaLanguage(countryEulaLanguage == null ? currentCountryEulaLanguages[0] : countryEulaLanguage);
    }
  }

  setEulaLanguage(language: { culture, value, label }) {
    this.selectedEulaLanguage = language;
    const countries: Array<any> = this.options['nationalAnnexes'];
      if (countries.length > 0) {
        const nationalAnnexes = countries.find(c => c.labelKey === this.selectedCountry).id;
        const designCode = countries.find(c => c.labelKey === this.selectedCountry).designCodeId;
        if (this.settings
          && (this.settings.countryNationalAnnexes !== nationalAnnexes)) {
          this.settings.countryNationalAnnexes = nationalAnnexes;
          this.settings.designCode = designCode;
          if (designCode === 2 && nationalAnnexes === 30) { // set default settings for US users
              this.settings.unit = AppConstants.UNIT_SYSTEM_US;
              this.settings.loadUnit = AppConstants.UNIT_KIP_ID;
              this.settings.lengthUnit = AppConstants.UNIT_FT_ID;
              this.settings.loadCategory = AppConstants.DEFAULT_LOADCATEGORY_US;
            } else {
              this.settings.unit = AppConstants.UNIT_SYSTEM_MI;
              this.settings.loadUnit = AppConstants.UNIT_KN_ID;
              this.settings.lengthUnit = AppConstants.UNIT_M_ID;
              this.settings.loadCategory = AppConstants.DEFAULT_LOADCATEGORY_EU;
          }
          if (designCode === 2) {
            this.settings.usDeadLoad1 = SettingsComponent.DEFAULT_US_DEAD_LOAD_1_AISC;
            this.settings.usDeadLoad2 = SettingsComponent.DEFAULT_US_DEAD_LOAD_2_AISC;
            this.settings.usLiveLoad2 = SettingsComponent.DEFAULT_US_LIVE_LOAD_2_AISC;
          }
          if (designCode === 3) {
            this.settings.usDeadLoad1 = SettingsComponent.DEFAULT_US_DEAD_LOAD_1_ASNZS;
            this.settings.usDeadLoad2 = SettingsComponent.DEFAULT_US_DEAD_LOAD_2_ASNZS;
            this.settings.usLiveLoad2 = SettingsComponent.DEFAULT_US_LIVE_LOAD_2_ASNZS;
            this.settings.asPhiM = SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiM;
            this.settings.asPhiMb = SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiMb;
            this.settings.asPhiVc = SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiVc;
            this.settings.asPhiVm = SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiVm;
            this.settings.asPhiS = SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiS;
            this.settings.asPhiC = SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiC;
            this.settings.asPhiVf = SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiVf;
            this.settings.asPhiSC = SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiSC;
            this.settings.asPhiSR = SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiSR;
            this.settings.loadCategory = AppConstants.DEFAULT_LOADCATEGORY_ASNZS;
          }
          this.settingsService.saveSettings(this.settings).subscribe(s => {
              this.settings = s;
              this.sharedService.changeSettings(s);
            });
          }
        }
    this.updateEulaText(`eula_${this.selectedEulaLanguage.culture}.htm`);
  }

  uiLanguageChange(event) {
    this.selectedUiLanguage = event;
    this.settings.language = event.value;
    this.sharedService.changeLanguage(this.selectedUiLanguage.value);
    this.settingsService.saveSettings(this.settings).subscribe(s => {
      this.settings = s;
    });

    const currentCountryEulaLanguages = this.eulaLanguages.get(this.selectedCountry);
    const countryEulaLanguage = currentCountryEulaLanguages.find(l => l.value === this.settings.language);
    this.setEulaLanguage(countryEulaLanguage == null ? currentCountryEulaLanguages[0] : countryEulaLanguage);
  }

  updateEulaText(eulaFile: string) {
    this.eulaService.getEulaText(eulaFile)
    .subscribe(eulaText => this.eulaText = eulaText.replace('{subProcessorListUrl}', AppConstants.SUBPROCESSOR_LIST_URL));
  }
}
