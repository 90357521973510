import pkceChallenge from 'pkce-challenge';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { AppStorageService } from './../util/app-storage.service';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';


import { HttpHeaders } from '@angular/common/http';
import { StateGenerator } from '../../common/StateGenerator';
import { environment } from 'environments/environment';
import { HttpClient } from '../../common/http-client';
import * as _ from 'lodash';
import { ConfigService } from '../config.service';
import { AppConstants } from '../../common/AppConstants';
import { Authentication } from '../../models/authentication';
import { SessionStorageService } from '../util/session-storage.service';
import { UserService } from './user.service';

@Injectable()
export class AuthService {
  private authenticationUrl: string;
  private oAuthCallbackUrl: string;
  private tokenUrl: string;
  private authenticated = false;
  private token: string;
  private expires: any = 0;
  private clientId: string;
  private user: UserService;
  private state: string;
  private authorizeUrl: string;
  private logoutUrl: string;
  // tslint:disable-next-line
  public static countries = [
    'US', 'IT', 'ES', 'FR', 'BE', 'LU', 'PT'
  ]
  // tslint:disable-next-line
  public static languages = [
    'en', 'it', 'es', 'fr', 'nl', 'pt'
  ];
  public cultures = new Array();
  // tslint:disable-next-line
  public static validLocales = [
    'en_US', 'it_IT', 'es_ES', 'fr_BE', 'nl_BE', 'fr_LU', 'pt_PT', 'fr_FR',
    'en-US', 'it-IT', 'es-ES', 'fr-BE', 'nl-BE', 'fr-LU', 'pt-PT', 'fr-FR',
    'en', 'it', 'es', 'fr', 'nl', 'pt'
  ];

  constructor(private configService: ConfigService, private http: HttpClient, private httpClient: HttpClient, user: UserService, private sessionStorageService: SessionStorageService,
    private activatedRoute: ActivatedRoute, private appStorageService: AppStorageService) {
    this.user = user;
    this.sessionStorageService = sessionStorageService;
    this.activatedRoute = activatedRoute;
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.state = params['state'];
    });

    this.authenticationUrl = this.configService.config.authenticationUrl;
    this.oAuthCallbackUrl = this.configService.config.callbackUrl;
    this.clientId = this.configService.config.clientId;
    this.tokenUrl = this.configService.config.tokenUrl;
    this.authorizeUrl = this.configService.config.authorizeUrl;
    this.logoutUrl = this.configService.config.logoutUrl;
    this.cultures.push({ lang: 'en', locale: 'en_US' });
    this.cultures.push({ lang: 'es', locale: 'es_ES' });
    this.cultures.push({ lang: 'pt', locale: 'pt_PT' });
    this.cultures.push({ lang: 'fr', locale: 'fr_FR' });
    this.cultures.push({ lang: 'nl', locale: 'nl_BE' });
    this.cultures.push({ lang: 'it', locale: 'it_IT' });
  }


  /**
   * Login to application
   **/
  public login(authenticationData: any) {
    const auth = {
      accessToken: authenticationData.access_token,
      subscriptionInfo: authenticationData.subscription_info,
      userId: authenticationData.subscription_info.UID,
      userName: authenticationData.subscription_info.LogonID,
      license: authenticationData.subscription_info.AuthorizationEntryList[0].Licenses,
      company: authenticationData.subscription_info.CustomerID,
      customerOriginID: authenticationData.subscription_info.AuthorizationEntryList[0].CustomerOriginID,
      eulaAcceptance: <string>this.appStorageService.get(AppConstants.HVB_EULA),
      countryOfResidence: <string>authenticationData.subscription_info.CountryOfResidence
    };
    const storagekey = this.appStorageService.get(AppConstants.LoginStateStorageKey);
    // console.log('Session Storage ', this.sessionStorageService);
    // if (auth.accessToken && (!storagekey || (storagekey && storagekey === this.state))) {
    if (auth.accessToken) {
      this.user.setAuthenticated(<Authentication>{
        accessToken: auth.accessToken,
        license: auth.license,
        userId: auth.userId,
        userName: auth.userName,
        company: auth.company,
        countryOfResidence: auth.countryOfResidence,
        customerOriginID: auth.customerOriginID,
        eulaAcceptance: auth.eulaAcceptance
      });


      this.appStorageService.remove(AppConstants.LoginStateStorageKey);

      return true;
    }

    return false;
  }
  public logout() {
    let accessToken = '';
    if (this.user && this.user.authentication) {
      accessToken = this.user.authentication.accessToken;
    }
    const lang = this.appStorageService.get<string>(AppConstants.LOCALE);
    const logoutUrl = this.authenticationUrl.concat('/', this.logoutUrl);

    const form = document.createElement('form');

    form.method = 'POST';
    form.action = logoutUrl;

    const clientIdInput = document.createElement('input');
    clientIdInput.value = this.clientId;
    clientIdInput.name = 'client_id';
    form.appendChild(clientIdInput);

    const langInput = document.createElement('input');
    langInput.value = lang;
    langInput.name = 'lang';
    form.appendChild(langInput);

    const accessTokenInput = document.createElement('input');
    accessTokenInput.value = accessToken;
    accessTokenInput.name = 'access_token';
    form.appendChild(accessTokenInput);

    const logoutUriInput = document.createElement('input');
    logoutUriInput.value = window.location.origin + '/logout';
    logoutUriInput.name = 'logout_uri';
    form.appendChild(logoutUriInput);

    this.invalidateLogin();
    if (!environment.localTesting) {
      form.style.display = 'none';
      document.body.appendChild(form);
      form.submit();
    }
  }

  public invalidateLogin() {
    // invalidate authentication on client
    this.user.invalidateAuthentication();
    this.user.removeOptions();
    this.sessionStorageService.remove(AppConstants.versionKey);
    this.appStorageService.remove(AppConstants.ReturnUrl);
    this.appStorageService.remove(AppConstants.LOCALE);
    this.appStorageService.remove(AppConstants.LoginStateStorageKey);
  }

  getAuthURL(): string {
    const state = StateGenerator.generateState();
    this.appStorageService.set<String>(AppConstants.LoginStateStorageKey, state);

    const locale = this.appStorageService.get<String>(AppConstants.LOCALE);
    const authURL = this.getAuthorizeUrl(locale.toString(), state);
    return authURL;
  }

  public getLocale(): string {
    let authLang = 'en_US';
    const navLanguage = window.navigator.language;
    if (!navLanguage) {
      return authLang;
    }
    if (navLanguage.length === 2) { // Format is 2 char like 'en' or 'it etc
      const result = this.cultures.find(item => item.lang === navLanguage);
      authLang = result ? result.locale : authLang;
    } else {
      let navLocale = navLanguage.split('-');
      if (navLocale.length === 2) {  // Format is 'en-US, 'it-IT' etc
        const navLocaleLang = navLocale[0] + '_' + navLocale[1];
        const result = AuthService.validLocales.find(lang => lang == navLocaleLang);
        authLang = result ? navLocaleLang : authLang;
      } else {
        navLocale = navLanguage.split('_');
        if (navLocale.length === 2) { // Format is 'en_US, 'it_IT' etc
          const result = AuthService.validLocales.find(lang => lang == navLanguage);
          authLang = result ? navLanguage : authLang;
        }
      }
    }
    return authLang;
  }

  public getCountry(): string {
    const locale = this.getLocale();
    const navLocale = locale.split('_'); // get locale returns in xx_YY format
    let authCountry = 'US';
    if (navLocale.length === 2) {
      const navCountry = navLocale[1];
      const result = AuthService.countries.find(country => country == navCountry);
      authCountry = result ? result : authCountry;
    }
    return authCountry;
  }

  public getAuthorizeUrl(lang: string, state: string): string {
    const currentLocale = this.getLocale(); // We will use window navigator language for the locale for auth related stuff

    const { code_verifier, code_challenge } = pkceChallenge();
    this.sessionStorageService.set('code_verifier', code_verifier);

    const url = this.authenticationUrl
      .concat('/', this.authorizeUrl)
      .concat('?client_id=', this.clientId)
      .concat('&redirect_uri=', this.oAuthCallbackUrl)
      .concat('&response_type=', 'code')
      .concat('&scope=', 'HC.Request.AllScopes')
      .concat('&state=', state)
      .concat('&lang=', currentLocale)
      .concat('&code_challenge=', code_challenge)
      .concat('&code_challenge_method=', 'S256');

    console.log('Calling Authorize URL ', url);
    console.log('Browser Locale ', window.navigator.language);
    return url;
  }


  public getToken(code: string, state: string): Observable<any> {
    // get access token via code
    if (!environment.localTesting) {
      const verifier = this.sessionStorageService.get<string>('code_verifier');
      this.sessionStorageService.remove('code_verifier');

      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

      const url = this.authenticationUrl.concat('/', this.tokenUrl);

      const data = ''
        .concat('grant_type=', 'authorization_code')
        .concat('&code=', code)
        .concat('&client_id=', this.clientId)
        .concat('&redirect_uri=', this.oAuthCallbackUrl)
        .concat('&code_verifier=', verifier);

      console.log('Getting access token with url and data:', ''.concat('url=', url, ' data=', data));
      return this.http.post(url, data, headers)
        .pipe(catchError((error: any) => observableThrowError(error ? error || 'unable-to-get-security-token' : 'unable-to-get-security-token'))); // ...errors if any
    } else {
      return this.http.get('assets/auth-response.json')
        .pipe(catchError((error: any) => observableThrowError(error ? error || 'unable-to-get-security-token' : 'unable-to-get-security-token'))); // ...errors if any
    }
  }

}
