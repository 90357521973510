import { map, mergeMap } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppConstants } from '../common/AppConstants';
import { SettingsService } from '../settings/settings.service';
import { SessionStorageService } from '../service/util/session-storage.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss']
})
export class TipsComponent implements OnInit {

  public tipsBodyFooter: string;

  constructor(public http: HttpClient,
    public sessionStorageService: SessionStorageService,
    public translate: TranslateService,
    public settingsService: SettingsService) {
      this.getCurrentUrls().subscribe(url => {
        this.tipsBodyFooter = translate.instant('tips-body-footer').replace('{tutorialUrl}', url.tutorual);
      });
    }

  ngOnInit() {
  }
  getCurrentUrls(): Observable<any> {
    const countries: Array<any> = this.sessionStorageService.get(AppConstants.optionListStorageKey)['nationalAnnexes'];
    return this.settingsService.getSettings().pipe(mergeMap(defaultSettings => {
      const currentCountry = countries.filter(c => c.id === defaultSettings.countryNationalAnnexes)[0].labelKey;
      return this.http.get(`./assets/url/${currentCountry.toLowerCase()}-url-setting.json`).pipe(map((res: Response) => res));
    }));
  }
}
