export interface Option<T> {
  id: number;
  label: string;
  labelKey: string;
  value: T;
}

export interface ConstructionOption extends Option<number> {
  designCode: number;
}

class NationalConcreteClasses {
  id: number;
  nationalAnnexId: number;
  concreteClassId: number;
}

export interface Options {
  beamDesigns: Option<number>[];
  beamLocations: Option<number>[];
  beamTypes: Option<number>[];
  combinationFactors: CombinationFactor[];
  concreteClasses: ConcreteClass[];
  concreteStrengths: ConcreteStrength[];
  concreteInterfaces: ConcreteInterface[];
  concreteTypes: Option<number>[];
  constructionTypes: ConstructionOption[];
  deflectionLimits: DeflectionLimit[];
  designCodes: Option<number>[];
  languages: Language[];
  loadUnits: LengthUnit[];
  lengthUnits: ForceUnit[];
  liveLoadPercents: Option<number>[];
  nationalAnnexes: NationalAnnex[];
  loadCategories: LoadCategory[];
  partialFactors: PartialFactor[];
  profileSheetings: ProfileSheeting[];
  reinforcementSteelGrades: Option<number>[];
  shearConnectionDegrees: Option<number>[];
  shearConnectorOrientations: Option<number>[];
  shearConnectorTypes: ShearConnectorType[];
  sheetingOrientations: Option<number>[];
  slabTypes: SlabType[];
  steelGrades: SteelGrade[];
  steelProfileRanges: SteelProfileRange[];
  steelProfiles: SteelProfile[];
  unitSystems: Option<number>[];
  walkingActivities: Option<number>[];
  rytmicExitations: Option<number>[];
  damplings: Damplings[];
  steelStandards: SteelStandards[];
  nationalSteelProfileRanges: NationalSteelProfileRanges[];
  nationalConcreteClasses: NationalConcreteClasses[];
  nationalShearConnectorTypes: NationalShearConnectorTypes[];
}

export interface NationalShearConnectorTypes {
  id: number;
  shearConnectorTypeId: number;
  nationalAnnexId: number;
}

export interface NationalSteelProfileRanges {
  id: number;
  nationalAnnexId: number;
  steelProfileRangeId: number;
}

export interface Damplings extends Option<number> {
  designCodeId: number;
}


export interface SteelStandards extends Option<number> {
  countryId: number;
}

export interface SteelGrade extends Option<number> {
  designCodeId: number;
  steelStandard: number;
}

export interface LengthUnit extends Option<number> {
  unitSystemId: number;
}

export interface DeflectionLimit extends Option<number> {
  designCodeId: number;
  showInLiveLoad: boolean;
  showInTotalLoad: boolean;
}

export interface ForceUnit extends Option<number> {
  unitSystemId: number;
}

export interface SlabType extends Option<number> {
  designCode: number;
}

export interface ShearConnectorType extends Option<number> {
  minSlabThicknessWithOutCorrosion?: string;
  minSlabThicknessWithCorrosion: string;
}

export interface Language extends Option<string> {
  referenceUrl: number;
  culture: number;
}

export interface CombinationFactor {
  id: number;
  value: number;
  nationalAnnexId: number;
  loadCategoryId: number;
}

export interface ConcreteClass extends Option<number> {
  concreteType: number[];
}

export interface ConcreteStrength extends Option<number> {
  unitSystemId: number;
}

export interface ConcreteInterface extends Option<string> {
  concreteTypeId: number;
  concreteTypeValue: number;
}

export interface DeflectionsCheck {
  liveLoadDeflecionCheck: boolean;
  liveLoadDeflecionMax: number;
  totalDeflectionCheck: boolean;
  totalDeflectionMax: number;
}

export interface Deformations {
  id?: number;
  constructionTypeId: number;
  precamber: number;
  totalDeflectionLimit: number;
  compositeDeflectionLimit: number;
  percentageLiveLoad: number;
  frequencyLimit: number;
  dampling?: number;
  walkingActivity?: number;
  walkingExcitationCheck?: number;
  rhythmicExcitationCheck?: number;
  rhythmicActivity?: number;
  incrementalDeflectionCheck?: number;
  performLateralTorsionBucklingCheck?: number;
  permanentLoadFraction?: number;
}

export interface LocationProperty {
  id?: number;
  location: number;
  value: number;
}

export interface LineLoad {
  id?: number;
  endLoad: LocationProperty;
  originLoad: LocationProperty;
}

export interface LoadCase {
  id: number;
  combinationFactor: number;
  lineLoads: LineLoad[];
  pointLoads: LocationProperty[];
  distributedSurfaceLoad: number;
  sequence: number;
}

export interface Loads {
  id: number;
  userCompositeSelfWeight: number;
  loadCases: LoadCase[];
}

export interface Location {
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  zip: string;
}

export interface PartialFactor {
  gammaG: number;
  gammaQ: number;
  gammaC: number;
  gammaM0: number;
  gammaM1: number;
  gammaS: number;
  gammaV: number;
  gammaP: number;
  asPhiM: number;
  asPhiMb: number;
  asPhiVc: number;
  asPhiVM: number;
  asPhiS: number;
  asPhiC: number;
  asPhiVf: number;
  asPhiSC: number;
  asPhiSR: number;
}

export interface ProfileSheeting {
  id?: number;
  profileName?: string;
  sheetingOrientationId?: number;
  sheetingOrientationValue?: number;
  pitch?: number;
  ribBottomWidth?: number;
  ribTopWidth?: number;
  ribHeight?: number;
  thickness?: number;
  surfaceWeight?: number;
  yieldStrength?: number;
  numberRibStiffeners?: number;
  ribStiffenerWidth?: number;
  stiffenersSpacing?: number;
  continuousForPerpendicular?: boolean;
  topStiffenerHeight?: number;
}

export interface ProjectGridView {
  id: number;
  name: string;
  createdOn: Date;
  updatedOn: Date;
  preparedBy: string;
  subProjects: SubProjectGridView[];
}

export interface SubProjectGridView {
  id: number;
  name: string;
  createdOn: Date;
}

export interface Project {
  id?: number;
  name: string;
  company: string;
  address1: string;
  address2?: string;
  city?: string;
  stateProvince?: string;
  postalCode?: string;
  country: string;
  contractor: string;
  reference?: string;
  responsible?: string;
  phone: string;
  email: string;
  preparedBy: string;
  designCode: number;
  countryNationalAnnexes: number;
  language: string;
  loadUnit: number;
  lengthUnit: number;
  loadCategory?: number;
  ulsGammaG: number;
  ulsGammaQ: number;
  structuralSteelSection: number;
  structuralSteelElement: number;
  concrete: number;
  connector: number;
  reinforcement: number;
  steelProfile: number;
  combinationFactor?: number;
  comments: string;
  unit: number;
  usDeadLoad1: number;
  usDeadLoad2: number;
  usLiveLoad2: number;
  usPhiElastic: number;
  asPhiM: number;
  asPhiMb: number;
  asPhiVc: number;
  asPhiVm: number;
  asPhiS: number;
  asPhiC: number;
  asPhiVf: number;
  asPhiSC: number;
  asPhiSR: number;
}

export interface SteelProfile {
  id?: number;
  customProfile: boolean;
  profileName?: string;
  steelGradeId: number;
  steelGradeValue?: number;
  height: number;
  flangeWidth: number;
  webThickness: number;
  flangeThickness: number;
  label: string;
  rootRadius: number;
  radius: number;
  yieldStrength?: number;
  elasticImposed?: boolean;
  class3Imposed?: boolean;
  steelStandardNZS?: number;
  unitSystem?: number;
}

export interface SteelProfileRange {
  beamRegion: string;
  description: string;
  dimension: string;
  id: number;
  profileRange: string;
  designCodeId: number;
}

export interface ConcreteSlab {
  id?: number;
  slabThickness: number;
  concreteTypeId: number;
  concreteTypeValue?: number;
  concreteClassId: number;
  concreteClassValue?: number;
  concreteDensity: number;
  coverRequired: boolean;
  haunchHeight?: number;
  concreteStrengthUS?: number;
  concreteStrengthId?: number;
  aggregatesDensity: number;
}

export interface BeamGeometry {
  id?: number;
  beamLength: number;
  leftSpacing: number;
  rightSpacing: number;
  beamTypeId: number;
  beamTypeValue?: number;
  beamLocationId: number;
  beamLocationValue?: number;
  slabTypeId: number;
  slabTypeValue?: number;
  leftOpening: boolean;
  leftOpeningPosition: number;
  rightOpening: boolean;
  rightOpeningPosition: number;
}

export interface ShearConnector {
  id?: number;
  connectionDegreeId: number;
  connectionDegreeValue?: number;
  connectorTypeId: number;
  connectorTypeValue?: number;
  connectorsOrientationId: number;
  connectorsOrientationValue?: number;
  userConnectionDegree: number;
  optimizationOption: number;
}

export interface Reinforcement {
  id?: number;
  steelGradeId: number;
  steelGradeValue?: number;
}

export interface SubProject {
  id?: number;
  projectId?: number;
  projectName?: string;
  createdOn?: string;
  updatedOn?: string;
  name?: string;
  beamGeometry?: BeamGeometry;
  steelProfile?: SteelProfile;
  concreteSlab?: ConcreteSlab;
  reinforcement?: Reinforcement;
  profileSheeting?: ProfileSheeting;
  shearConnector?: ShearConnector;
  load?: Loads;
  deformation?: Deformations;
  partialFactors: PartialFactor;
  loadCategory: number;
  combinationFactor: number;
  liveLoadReduction: boolean;
  designCode: number;
}

export interface LoadCategory {
  id: number;
  value: number;
  label: string;
  labelKey: string;
  designCodeId: number;
  liveLoadReduction: boolean;
}

export interface NationalAnnex {
  id: number;
  value: number;
  label: string;
  labelKey: string;
  designCodeId: number;
  countryCode: string;
}

export interface Drawing {
  pictureWidth: number;
  pictureHeight: number;
  lengthUnit: number;
  showSymbol: boolean;
  unitSystem: number;
  designCode: number;
}

export interface SteelSectionDrawing extends Drawing {
  steelProfile: SteelProfile;
  profileSheeting?: ProfileSheeting;
  drawingType: number;
}

export interface SlabDrawing extends Drawing {
  beamGeometry: BeamGeometry;
  steelProfile: SteelProfile;
  profileSheeting?: ProfileSheeting;
  concreteSlab: ConcreteSlab;
}

export interface BeamLoadDrawing extends Drawing {
  beamGeometry: BeamGeometry;
  steelProfile: SteelProfile;
}

export interface BeamGeometryDrawing extends Drawing {
  beamGeometry: BeamGeometry;
}

export interface CompositeCrossSectionDrawing extends Drawing {
  concreteSlab: ConcreteSlab;
  beamGeometry: BeamGeometry;
  profileSheeting?: ProfileSheeting;
  shearConnector?: ShearConnector;
  steelProfile: SteelProfile;
  connectorsSpacing?: number;
  numberOfRows: number;
}

export interface CompositeSelfWeight {
  concreteSlab: ConcreteSlab;
  beamGeometry: BeamGeometry;
  steelProfile: SteelProfile;
  profileSheeting: ProfileSheeting;
}

export interface DefaultCompositeLiveLoad {
  generalInformation: Calculate.GeneralInformation;
  beamGeometry: BeamGeometry;
}
export interface LoadDrawing extends Drawing {
  concreteSlab: ConcreteSlab;
  beamGeometry: BeamGeometry;
  steelProfile: SteelProfile;
  loads: Loads;
  loadCaseIndex: number;
  forceUnit: number;
  type: Calculate.LoadDrawingType;
}
// tslint:disable-next-line
export interface CompositeBeamDrawing extends CompositeCrossSectionDrawing {

}

export interface ProfiledSheetingDrawing extends Drawing {
  profileSheeting: ProfileSheeting;
}

export interface Version {
  first: number;
  second: number;
  third: number;
  beta: number;
}

export interface Settings {
  designCode: number;
  countryNationalAnnexes: number;
  language: string;
  loadUnit: number;
  lengthUnit: number;
  loadCategory: number;
  ulsGammaG: number;
  ulsGammaQ: number;
  structuralSteelSection: number;
  structuralSteelElement: number;
  concrete: number;
  connector: number;
  reinforcement: number;
  steelProfile: number;
  combinationFactor: number;
  trackingPolicy: boolean;
  region: string;
  customerId?: number;
  name: string;
  eTag?: string;
  unit: number;
  usDeadLoad1: number;
  usDeadLoad2: number;
  usLiveLoad2: number;
  usPhiElastic: number;
  liveLoadReduction: boolean;
  asPhiM: number;
  asPhiMb: number;
  asPhiVc: number;
  asPhiVm: number;
  asPhiS: number;
  asPhiC: number;
  asPhiVf: number;
  asPhiSC: number;
  asPhiSR: number;
}

export interface GridProject {
  id: number;
  name: string;
  selected: boolean;
  subProjects: Map<number, GridSubProject>;
}

export interface GridSubProject {
  id: number;
  selected: boolean;
}

export namespace Calculate {

  // command

  export interface CreateCommand {
    generalInformation: GeneralInformation;
    partialFactors: PartialFactors;
    beamGeometry: BeamGeometry;
    concreteSlab: ConcreteSlab;
    profileSheeting: ProfileSheeting;
    shearConnector: ShearConnector;
    steelProfile: SteelProfile;
    reInforcementSteelBars: ReInforcementSteelBars;
    loads: Loads;
    deformations: Deformations;
    version: Version;
    lengthUnit: LengthUnitEnum;
    forceUnit: ForceUnitEnum;
    designCode: number;
    detailedSheet: boolean;
  }

  export interface Deformations {
    constructionTypeValue: number;
    precamber: number;
    totalDeflectionLimit: number;
    compositeDeflectionLimit: number;
    percentageLiveLoad: number;
    frequencyLimit: number;
    incrementalDeflectionCheck: boolean;
    permanentLoadFraction: number;
    rhythmicExcitationCheck?: number;
    performLateralTorsionBucklingCheck?: boolean;
  }

  export interface Loads {
    id: number;
    loadCases: LoadCase[];
    userCompositeSelfWeight: number;
  }

  export interface LoadCases {
    id: number;
    combinationFactor: number;
    distributedSurfaceLoad: number;
    pointLoads: LocationProperty[];
    lineLoads: LineLoad[];
    sequence: number;
  }

  export interface LineLoad {
    originLoad: LocationProperty;
    endLoad: LocationProperty;
  }

  export interface LocationProperty {
    location: number;
    value: number;
  }


  export interface ReInforcementSteelBars {
    steelGradeValue: number;
  }

  export interface SteelProfile {
    customProfile: boolean;
    profileName: string;
    steelGradeValue: number;
    height: number;
    flangeWidth: number;
    webThickness: number;
    flangeThickness: number;
    rootRadius: number;
    radius: number;
    yieldStrength: number;
    elasticImposed: boolean;
    class3Imposed: boolean;
  }

  export interface ShearConnector {
    connectionDegreeValue: number;
    connectorTypeValue: number;
    connectorsOrientationValue: number;
    userConnectionDegree: number;
    optimizationOption: number;
  }

  export interface ProfileSheeting {
    profileName: string;
    sheetingOrientationValue: number;
    pitch: number;
    ribBottomWidth: number;
    ribTopWidth: number;
    ribHeight: number;
    thickness: number;
    surfaceWeight: number;
    yieldStrength: number;
    numberRibStiffeners: number;
    ribStiffenerWidth: number;
    stiffenersSpacing: number;
    continuousForPerpendicular: boolean;
    topStiffenerHeight: number;
  }

  export interface ConcreteSlab {
    slabThickness: number;
    concreteTypeValue: number;
    concreteClassValue: number;
    concreteDensity: number;
    coverRequired: boolean;
  }

  export interface BeamGeometry {
    beamLength: number;
    leftSpacing: number;
    rightSpacing: number;
    beamTypeValue: number;
    beamLocationValue: number;
    slabTypeValue: number;
  }

  export enum LoadDrawingType {
    SIMPLE_LOADS = 0,
    LINE_LOADS = 1,
    POINT_LOADS = 2
  }

  export enum LengthUnitEnum {
    METER = 1,
    CENTIMETER = 2,
    MILLIMETER = 3,
    FOOT = 4
  }

  export enum ForceUnitEnum {
    KILO_NEWTONS = 1,
    DECA_NEWTONS = 2,
    NEWTONS = 3,
    POUND_FORCE = 4,
    KILO_POUND = 5
  }

  export interface PartialFactors {
    gammaG: number;
    gammaQ: number;
    gammaC: number;
    gammaM0: number;
    gammaM1: number;
    gammaS: number;
    gammaV: number;
    gammaP: number;
    combinationFactor: number;
    asPhiC: number;
    asPhiM: number;
    asPhiMb: number;
    asPhiS: number;
    asPhiSC: number;
    asPhiSR: number;
    asPhiVM: number;
    asPhiVc: number;
    asPhiVf: number;
  }

  export interface GeneralInformation {
    companyAddress: string;
    companyName: string;
    nationalAnnexValue: number;
    projectName: string;
    projectReferences: string;
    subProject: string;
    contractor: string;
    userName: string;
    loadedAreasCategory: number;
    scopeExtensions: ScopeExtensions;
    phoneFax: string;
    website: string;
    comments: string;
    email: string;
    date: string;
    standard: number;
    unit: number;
    liveLoadReduction: boolean;
  }

  export interface ScopeExtensions {
    extension1: boolean;
    extension2: boolean;
  }

  // result

  export interface ConstructionStageResult {
    ltbCriterion: number;
    ltbResistance: number;
    sectionResistance: SectionResistanceCheck;
    totalDeflectionMax: number;
    liveLoadDeflectionMax: number;
    lfrd: LfrdCheckUs;
  }

  export interface LfrdCheckUs {
    flangeClass: number;
    cfyStrength: number;
    cfyCriterion: number;
    ltbStrength: number;
    ltbCriterion: number;
    ltbBendingMoment: number;
    shearStrength: number;
    shearCriterion: number;
    flbCriterion: number;
    shearMax: number;
    steelNormalStressMax: number;
    concreteNormalStressCriterion: number;
    concreteNormalStressMax: number;
    yieldingStrength: number;
    yieldingCriterion: number;
    bendingMomentMax: number;
    webClass: number;
    steelNormalStressCriterion: number;
    flbStrength: number;
  }

  export interface TransverseReinforcement {
    minAreaTopReinforcement: number;
    minAreaBottomReinforcement: number;
    designStress: number;
    designResistance: number;
    criterion: number;
  }

  export interface SectionResistanceCheck {
    bendingMomentCriterion: number;
    bendingMomentMax: number;
    momentForMomentShear: number;
    momentShearCriterion: number;
    momentShearResistance: number;
    plasticMomentResistance: number;
    shearForMomentShear: number;
    verticalShearCriterion: number;
    verticalShearMax: number;
    verticalShearResistance: number;
    shearWebResistance: number;
    shearWebCriterion: number;
    momentWebBuckling: number;
    shearWebBuckling: number;
    momentShearWebCriterion: number;
    sectionClass: number;
  }

  export interface FinalStageResult {
    crowdMovementFrequency?: number;
    minimumFrequency: number;
    sectionResistance: SectionResistanceCheck;
    totalDeflectionMax: number;
    liveLoadDeflectionMax: number;
    walkingAcceleration?: Acceleration;
    rhythmicAcceleration?: Acceleration;
    lfrd?: LfrdCheckUs;
  }

  export interface Acceleration {
    naturalFrequency: number;
    accelerationLimit: number;
    acceleration: number;
  }

  export interface ShearConnectionResult {
    connectionDegree: number;
    connectorsSpacing: number;
    minConnectionDegree: number;
    numberOfConnectors: number;
    numberOfRows: number;
    reductionFactor: number;
    shearResistance: number;
    limConnectionDegree: number;
    isOptimized: boolean;
    numberOfZones: number;
    listOfShearResistance: number[];
    listOfReductionFactor: number[];
    listOfNumberOfConnectors: number[];
    listOfNumberOfRows: number[];
    listOfConnectorsSpacing: number[];
    listOfLeftPosition: number[];
    listOfRightPosition: number[];
  }

  export interface CalculationResult {
    errorCodes: number[];
    errorCodesNotStop: number[];
    constructionStageResult: ConstructionStageResult;
    finalStageResult: FinalStageResult;
    shearConnectionResult: ShearConnectionResult;
    reinforcementResult: TransverseReinforcement;
    pdfStream: any;
    listOfNotes: string[];
    designCriteriaOk: boolean;
    isElasticFinalStage: boolean;
    ltbCriterionIsOK: boolean;
  }

  export interface DXFDrawing {
    concreteSlab: ConcreteSlab;
    beamGeometry: BeamGeometry;
    profileSheeting?: ProfileSheeting;
    shearConnector?: ShearConnector;
    steelProfile: SteelProfile;
    shearConnectionResult: ShearConnectionResult;
    lengthUnit: number;
    language: string;
  }

  export interface DxfResult {
    drawing: string;
    drawingSuccessful: boolean;
  }

  export interface BOMInput {
    generalInformation: GeneralInformation;
    shearConnector: ShearConnector;
    shearConnectionResult: ShearConnectionResult;
    numberOfConnectors: Number;
    connectorType: Number;
    version: Version;
    language: string;
  }

  export interface BOMResult {
    errorCode: number;
    pdfStream: any;
  }

  export interface ConnectionZonesDrawing extends Drawing {
    concreteSlab: ConcreteSlab;
    beamGeometry: BeamGeometry;
    profileSheeting?: ProfileSheeting;
    steelProfile: SteelProfile;
    shearConnectionResult: ShearConnectionResult;
    language: string;
}
}

export interface PersonalDataGrid {
  email: string;
  company: string;
  tracking: boolean;
  data: PersonalData[];
}

export interface PersonalData {
  project: string;
  phoneNumber: string;
  address: string;
  preparedBy: string;
  email: string;
  company: string;
}

export interface ReleaseNote {
  title: string;
  content: string[];
  isCollapsed: boolean;
}

export interface UnitSystem {
  lengthUnitTitle: string;
  forceUnitTitle: string;
  dimensionUnitTitle: string;
  densityUnitTitle: string;
  stressUnitTitle: string;
  stressUnitForMaterialsTitle: string;
  surfaceWeightUnitTitle: string;
}
export interface UsGage {
  gage: number;
  inch: number;
}

export interface ErrorCodesTranslationValidationRanges {
  beamLengthMin: number;
  beamLengthMax: number;
  beamLengthUnit: string;
  leftSpacingMinLength: number;
  leftSpacingMaxLength: number;
  steelStrengthMin: number;
  steelStrengthMax: number;
  steelStrengthUnit: string;
  concreteDensityMin: number;
  concreteDensityMax: number;
  concreteDensityUnit: string;
  sheetingOrientationStrengthMin: number;
  sheetingOrientationStrengthMax: number;
  steelProfileWebThicknessMin: number;
  steelProfileFlangeWidth: number;
  dimensionUnitTitle: string;
  incorectSlabThicknessIncreaseValue: number;
  profileSheetingThicknessMin: number;
  profileSheetingThicknessMax: number;
  profileSheetingCustomTroughspacingMin: number;
  profileSheetingCustomTroughspacingMax: number;
  profileSheetingBottomWidthMin: number;
  profileSheetingBottomWidthMax: number;
  savMax: number;
  profileSheetingCustomSurfaceWidthMin: number;
  profileSheetingCustomSurfaceWidthMax: number;
  surfaceWeightUnitTitle: string;
  profileSheetingThicknessSmallConnectors: number;
  profileSheetingThicknessLargeConnectors: number;
}

export interface BrowserInformation {
  name: string;
  version: number;
}

export interface AgregatedDensityResult {
  density: number;
}
