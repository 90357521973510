import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/service/common/auth.service';
import { StateGenerator } from './../common/StateGenerator';
import { AppStorageService } from 'app/service/util/app-storage.service';
import { AppConstants } from './AppConstants';

@Injectable()
export class Interceptor implements HttpInterceptor {

	constructor(
		private authService: AuthService,
		private appStorageService: AppStorageService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		console.log('intercept request');

		return next.handle(req)
			.pipe(
				catchError(async error => {
					await this.handleError(req, error);

					throw error;
				})
			);
	}

	private async handleError(req: HttpRequest<any>, error: unknown) {
		const httpResponse = error instanceof HttpResponseBase ? error : undefined;

		if (httpResponse != null && httpResponse.status === 401) {
			location.assign(this.getAuthURL());
		}
	}

	private getAuthURL(): string {
		const state = StateGenerator.generateState();
		this.appStorageService.set<String>(AppConstants.LoginStateStorageKey, state);

		const locale = this.appStorageService.get<String>(AppConstants.LOCALE);
		const authURL = this.authService.getAuthorizeUrl(locale.toString(), state);
		return authURL;
	}
}
