import { UserAgreementAcceptGaurd } from './../user-agreement-acceptguard.service';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { SortingComponent } from './../shared/sorting/sorting.component';
import { ProjectListComponent } from './project-list.component';
import { ProjectService } from './project.service';
import { RouterModule } from '@angular/router';
import {BodyModule, GridModule} from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ProjectComponent } from './project.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonService } from './../service/common/common.service';
import { SubProjectModule } from './../subproject/sub-project.module';
import { RouteGuard } from './../service/common/routeguard.service';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { SearchService } from '../shared/search/search.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProjectSaveGuard } from './project-saveguard.service';
import { ProjectNameValidator } from './projectNameValidator.directive';
import {SettingsModule} from '../settings/settings.module';
import {ButtonModule, CheckboxModule, LabelModule} from 'hilti-ui-library';
import {DropDownListModule} from '@progress/kendo-angular-dropdowns';
@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild([
            {path: 'projects', component: ProjectListComponent, canActivate: [RouteGuard, UserAgreementAcceptGaurd]},
            {
                path: 'project',
                component: ProjectComponent,
                canActivate: [RouteGuard, UserAgreementAcceptGaurd],
                canDeactivate: [ProjectSaveGuard]
            },
            {
                path: 'project/:id',
                component: ProjectComponent,
                canActivate: [RouteGuard, UserAgreementAcceptGaurd],
                canDeactivate: [ProjectSaveGuard]
            }
        ]),
        GridModule,
        LayoutModule,
        ReactiveFormsModule,
        SubProjectModule,
        DialogModule,
        FormsModule,
        BrowserAnimationsModule,
        SettingsModule,
        CheckboxModule,
        BodyModule,
        ButtonModule,
        LabelModule,
        DropDownListModule
    ],
  declarations: [
    ProjectListComponent,
    ProjectComponent,
    SortingComponent,
    ProjectNameValidator
  ],
  providers: [
    ProjectService,
    CommonService,
    SearchService,
    ProjectSaveGuard,
    UserAgreementAcceptGaurd
  ]
})
export class ProjectModule { }
