export const AppConstants = {
  RegistrationStateStorageKey: 'registrationState',
  RegistrationReturnUrl: 'registrationReturnUrl',
  RegistrationCallback: '/registration_callback',
  LoginStateStorageKey: 'loginState',
  OauthCallBack: 'oauth_callback',
  ReturnUrl: 'return_url',
  authenticationStorageKey: 'authentication',
  optionListStorageKey: 'options',
  versionKey: 'version',
  LOCALE: 'locale',
  HVB_EULA: 'hvb_eula',
  HVB_PREVIOUS_USER: 'hvb_previous_user',
  HVB_PRIVACY_POLICY: 'hvb_privacy_policy',
  SHOW_COOKIE_INFO: 'show_cookie_info',
  DEFAULT_REFERENCE_URL: 'https://www.hilti.com.tr/medias/sys_master/documents/h4c/9154842722334/X-HVB_Specification_Binder_Technical_information_ASSET_DOC_4666585.pdf',
  DEFAULT_TECH_SPEC_EC: 'assets/references/HSCD - Technical specifications - DRV_HVB_MT_003_K.pdf',
  DEFAULT_TECH_SPEC_AISC: 'assets/references/HSCD - Technical specifications - DRV_HVB_MT_006_E.pdf',
  DEFAULT_TECH_SPEC_ASNZS: 'assets/references/HSCD - Technical specifications - DRV_HVB_MT_009_C.pdf',
  DEFAULT_LICENSE: 'assets/licenses/LICENSES.txt',
  PRIVACY_BASE_URL: 'assets/references/privacyPolicy/Privacy_Policy_${lang}.pdf',
  PRIVACY_MARKETING_URL: 'preferences?email:${email}&uid:${uid}&country:${country}&lang:${lang}',
  SUBPROCESSOR_LIST_URL: 'https://cfs-prod-profis3--docs.s3.amazonaws.com/P3HVB/AGR/SubprocessorList_en.pdf',
  DEFAULT_LOADCATEGORY_EU: 1,
  DEFAULT_LOADCATEGORY_US: 7,
  DEFAULT_LOADCATEGORY_ASNZS: 18,
  UNIT_SYSTEM_MI: 1,
  UNIT_SYSTEM_US: 2,
  UNIT_MM_ID: 1,
  UNIT_CM_ID: 2,
  UNIT_M_ID: 3,
  UNIT_KN_ID: 1,
  UNIT_daN_ID: 2,
  UNIT_N_ID: 3,
  UNIT_FT_ID: 4,
  UNIT_KIP_ID: 4,
  UNIT_LBF_ID: 5,
  UNIT_MM_STR: 'mm',
  UNIT_CM_STR: 'cm',
  UNIT_M_STR: 'm',
  UNIT_KN_STR: 'kN',
  UNIT_daN_STR: 'daN',
  UNIT_N_STR: 'N',
  UNIT_FT_STR: 'ft',
  UNIT_IN_STR: 'in',
  UNIT_LBF_STR: 'lbf',
  UNIT_KIP_STR: 'kip',
  UNIT_KG_M_STR: 'kg/㎥',
  UNIT_LB_FT_STR: 'lb/ft',
  UNIT_MPA_STR: 'MPa',
  UNIT_PSI_STR: 'psi',
  UNIT_KSI_STR: 'ksi',
  APPLICATION_ID: '0DC6BD51-4D46-4857-A2A4-8149B9174618',
  E_TAG: '1.0.0.0'
}
