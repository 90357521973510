import { HttpClient } from './../../common/http-client';

import {empty as observableEmpty,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppConstants } from './../../common/AppConstants';
import { SettingsService } from '../../settings/settings.service';
import { SessionStorageService } from './../../service/util/session-storage.service';

@Injectable()
export class UrlService {

  constructor(
    private _http: HttpClient,
    private _settingsService: SettingsService,
    private _sessionStorageService: SessionStorageService) {
  }

  public getCurrentUrls(): Observable<any> {
    return this._settingsService.getSettings().pipe(mergeMap(settings => {
      return this.getCurrentUrlsBySettings(settings);
    }));
  }

  public getCurrentUrlsBySettings(settings): Observable<any> {
    const countries: Array<any> = this._sessionStorageService.get<Array<any>>(AppConstants.optionListStorageKey);
    if (settings && countries) {
      const currentCountry = countries['nationalAnnexes'].filter(c => c.id === settings.countryNationalAnnexes)[0].labelKey;
      return this._http.get(`./assets/url/${currentCountry.toLowerCase()}-url-setting.json`);
    } else {
      return observableEmpty();
    }
  }
}
