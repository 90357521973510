import { TranslateService } from '@ngx-translate/core';
import { ToasterService, Toast } from 'angular2-toaster';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Settings, UnitSystem } from '../../models/models';

@Injectable()
export class SharedService {
  private _breadcrumbSource = new BehaviorSubject<string>('home');
  breadCrumb$ = this._breadcrumbSource.asObservable();

  // Observable lang Source
  private _languageSource = new BehaviorSubject<string>('en');
  // Observable lang stream
  language$ = this._languageSource.asObservable();

  // control the show EULA dialog
  private _showEulaDialog = new BehaviorSubject<boolean>(false);
  public $showEulaDialog = this._showEulaDialog.asObservable();

  // control the show dialog after login
  private _showDialogAfterLogin = new BehaviorSubject<boolean>(false);
  public $showDialogAfterLogin = this._showDialogAfterLogin.asObservable();

  // control the loading screen
  private _loaderSource = new BehaviorSubject<boolean>(false);
  public $loader = this._loaderSource.asObservable();

  private _pdfDataReady = new BehaviorSubject<any>(null);
  public $pdfDataReady = this._pdfDataReady.asObservable();

  private _settingsSource = new BehaviorSubject<Settings>(null);
  public $settings = this._settingsSource.asObservable();

  constructor(private toasterService: ToasterService,
    private translate: TranslateService) {
  }

  public showEulaDialog(show: boolean) {
    this._showEulaDialog.next(show);
  }

  public showDialogAfterLogin(show: boolean) {
    this._showDialogAfterLogin.next(show);
  }

  public showLoader(show: boolean) {
    this._loaderSource.next(show);
  }
  // service command
  changeLanguage(lang) {
    if (this._languageSource.value !== lang) {
      this._languageSource.next(lang);
    }
  }
  // service command
  changeSettings(settings) {
    this._settingsSource.next(settings);
  }
  // service command
  changeBreadCrumb(breadcrumb) {
    this._breadcrumbSource.next(breadcrumb);
  }
  public pdfDataRready(pdfData) {
    this._pdfDataReady.next(pdfData);
  }
  public popToast = (toastType: string, body: string) => {
    this.toasterService.pop(<Toast>{
      type: toastType,
      body: this.translate.instant(body)
    });
  }

  public popFailure = (body: string) => {
    this.popToast('error', body);
  }

  public popSuccess = (body: string) => {
    this.popToast('success', body);
  }

  public getSupportedLocales() {
    const languages = new Map<string, { culture, value, label, isoCode }[]>();
    languages.set('argentina', [{ 'culture': 'es_AR', 'label': 'spanish', 'value': 'es', isoCode: 'AR' }]);
    languages.set('australia', [{ 'culture': 'en_AU', 'label': 'english', 'value': 'en', 'isoCode': 'AU' }]);
    languages.set('austria', [{ 'culture': 'en_AT', 'label': 'german', 'value': 'de', 'isoCode': 'AT' }]);
    languages.set('belgium', [
      { 'culture': 'fr_BE', 'value': 'fr', 'label': 'french', 'isoCode': 'BE' },
      { 'culture': 'nl_BE', 'value': 'nl', 'label': 'dutch', 'isoCode': 'BE' }
    ]);
    languages.set('brazil', [{ 'culture': 'pt_BR', 'label': 'Portuguese', 'value': 'pt', isoCode: 'BR' }]);
    languages.set('bulgaria', [
      { 'culture': 'bg_BG', 'label': 'bulgarian', 'value': 'bg', 'isoCode': 'BG' },
      { 'culture': 'en_BG', 'label': 'english', 'value': 'en', 'isoCode': 'BG' }
    ]);
    languages.set('canada', [
      { 'culture': 'en_US', 'label': 'english', value: 'en', 'isoCode': 'CA' }
    ]);
    languages.set('chile', [{ 'culture': 'es_CL', 'label': 'spanish', 'value': 'es', isoCode: 'CL' }]);
    languages.set('colombia', [{ 'culture': 'es_CO', 'label': 'spanish', 'value': 'es', isoCode: 'CO' }]);
    languages.set('croatia', [{ 'culture': 'en_HR', 'label': 'english', 'value': 'en', isoCode: 'HR' }]);
    languages.set('czech-republic', [
      { 'culture': 'cs_CZ', 'label': 'czech', 'value': 'cs', 'isoCode': 'CZ' },
      { 'culture': 'en_CZ', 'label': 'english', 'value': 'en', 'isoCode': 'CZ' }
    ]);
    // languages.set('estonia', [{ 'culture': 'en_US', 'label': 'english', 'value': 'en' }]);
    languages.set('france', [{ 'culture': 'fr_FR', 'label': 'french', 'value': 'fr', 'isoCode': 'FR' }]);
    languages.set('germany', [{ 'culture': 'en_DE', 'label': 'german', 'value': 'de', 'isoCode': 'DE' }]);
    languages.set('greece', [{ 'culture': 'en_GR', 'label': 'english', 'value': 'en', 'isoCode': 'GR' }]);
    languages.set('hungary', [
      { 'culture': 'hu_HU', 'label': 'hungarian', 'value': 'hu', 'isoCode': 'HU' },
      { 'culture': 'en_HU', 'label': 'english', 'value': 'en', 'isoCode': 'HU' }
    ]);
    languages.set('italy', [{ 'culture': 'it_IT', 'label': 'italian', 'value': 'it', 'isoCode': 'IT' }]);
    languages.set('israel', [{ 'culture': 'il_EN', 'label': 'english', 'value': 'en', 'isoCode': 'IL' }]);
    // languages.set('latvia', [{ 'culture': 'en_US', 'label': 'english', 'value': 'en' }]);
    // languages.set('lithuania', [{ 'culture': 'en_US', 'label': 'english', 'value': 'en' }]);
    languages.set('luxemburg', [{ 'culture': 'fr_LU', 'label': 'french', 'value': 'fr', 'isoCode': 'LU' }]);
    languages.set('mexico', [{ 'culture': 'es_MX', 'label': 'spanish', 'value': 'es', isoCode: 'MX' }]);
    languages.set('netherlands', [{ 'culture': 'en_NL', 'label': 'dutch', 'value': 'nl', 'isoCode': 'NL' }]);
    languages.set('new-zealand', [{ 'culture': 'en_NZ', 'label': 'english', 'value': 'en', 'isoCode': 'NZ' }]);
    languages.set('peru', [{ 'culture': 'es_PE', 'label': 'spanish', 'value': 'es', isoCode: 'PE' }]);
    languages.set('poland', [{ 'culture': 'en_PL', 'label': 'polish', 'value': 'pl', 'isoCode': 'PL' }]);
    languages.set('portugal', [{ 'culture': 'pt_PT', 'label': 'portuguese', 'value': 'pt', 'isoCode': 'PT' }]);
    languages.set('qatar', [{ 'culture': 'ar_QA', 'label': 'english', 'value': 'en', 'isoCode': 'QA' }]);
    languages.set('romania', [
      { 'culture': 'ro_RO', 'label': 'romanian', 'value': 'ro', 'isoCode': 'RO' },
      { 'culture': 'en_RO', 'label': 'english', 'value': 'en', 'isoCode': 'RO' }
    ]);
    languages.set('saudi-arabia', [{ 'culture': 'ar_SA', 'label': 'english', 'value': 'en', 'isoCode': 'SA' }]);
    languages.set('serbia', [{ 'culture': 'en_RS', 'label': 'english', 'value': 'en', 'isoCode': 'RS' }]);
    languages.set('singapore', [{ 'culture': 'en_SG', 'label': 'english', 'value': 'en', 'isoCode': 'SG' }]);
    languages.set('slovakia', [
      { 'culture': 'sk_SK', 'label': 'slovak', 'value': 'sk', 'isoCode': 'SK' },
      { 'culture': 'en_SK', 'label': 'english', 'value': 'en', 'isoCode': 'SK' }
    ]);
    languages.set('slovenia', [
      { 'culture': 'sl_SI', 'label': 'Slovenian', 'value': 'en', 'isoCode': 'SI' }
    ]);
    languages.set('south-africa', [{ 'culture': 'en_ZA', 'label': 'english', 'value': 'en', isoCode: 'ZA' }]);
    languages.set('spain', [{ 'culture': 'es_ES', 'label': 'spanish', 'value': 'es', isoCode: 'ES' }]);
    languages.set('switzerland', [{ 'culture': 'en_CH', 'label': 'german', 'value': 'de', isoCode: 'CH' }]);
    languages.set('turkey', [{ 'culture': 'tr_TR', 'label': 'Turkish', 'value': 'tr', isoCode: 'TR' }]);
    languages.set('emirates', [{ 'culture': 'ar_AE', 'label': 'english', 'value': 'en', isoCode: 'AE' }]);
    languages.set('united-kingdom', [{ 'culture': 'en_GB', 'label': 'english', 'value': 'en', isoCode: 'GB' }]);
    languages.set('united-states', [{ 'culture': 'en_US', 'label': 'English', 'value': 'en', isoCode: 'US' }]);
    languages.set('none', [{ 'culture': 'en_US', 'label': 'english', 'value': 'en', isoCode: 'none' }]);
    return languages;
  }
}
