
import { Injectable } from '@angular/core';
import { UserService } from '../service/common/user.service';
import { HttpClient } from './../common/http-client';

import { ConfigService } from 'app/service/config.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import * as models from './../models/models';


@Injectable()
export class SettingsService {
  private _usersUrl: string;
  constructor(private configService: ConfigService, private _http: HttpClient, private userService: UserService) {
    if (environment.localTesting) {
      this._usersUrl = `${this.configService.config.serviceUrl}users/`;
    } else {
      this._usersUrl = `${this.configService.config.serviceUrl}asws/profishvb/shear_connector_users/v1/`;
    }
  }

  public getSettings(): Observable<models.Settings> {
    return this._http
      .get<models.Settings>(`${this._usersUrl}settings`);
  }

  public saveSettings(settings: models.Settings): Observable<any> {
    // console.log('Settings: ' + JSON.stringify(settings));
    return this._http
      .put(`${this._usersUrl}settings`, settings);
  }
}
